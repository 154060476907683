@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:wght@100&family=Secular+One&display=swap');



@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Pacifico&family=Poppins:wght@300&family=Secular+One&display=swap');


  .vaca-title{
    width: 60px;
    height: 60px;
  }

  .title-remates {
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 1s ease-out, transform 1s ease-out;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 300;
  }
  
  .title-remates-visible {
    opacity: 1;
    transform: translateX(0);
  }

  .content-biscardi-mobile{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .card-img-biscardi-mobile{
    width: 84%;
    height: 500px;
    border-radius: 10px;
  }

  .content-biscardi-desktop{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .card-img-biscardi-desktop{
    width: 55%;
    height: 500px;
    border-radius: 10px;
  }

  .card {
    display: flex;
    flex-direction: row;
    background-color: white;
    width: 55%;
    margin: 0 auto;
    height: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    
  }
  
  .card h4 {
    margin: 10px 0;
  }

  .card .hacienda {
    margin: 10px 0;
    font-weight: 600;
    text-transform: uppercase;
    color:#233C36;
  }
  
  
  .content-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 74%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .content-img h4 {
    margin: 0;
  }
  
  .content-img .cantidad {
    font-size: 6rem;
    font-family: 'poppins';
    color: white;
    height: 95px;
  }
  
  .content-img .vacunos {
    font-size: 2.8rem;
    font-family: 'poppins';
    color: white;
    height: 65px;
  }
  
  .content-img .tipo {
    font-size: 0.8rem;
    font-family: 'poppins';
    color: #233C36;
    background-color: #efd79b;
    border-radius: 4px;
    list-style: disc inside none;
    position: relative;
    bottom: 10px;
  }
  
  .content-img .tipo::before,
  .content-img .tipo::after {
    content: "\2022";
    color: #233C36;
    font-weight: bold;
    display: inline-block;
    width: 0.8rem;
    text-align: center;
  }
  
  .card-column1 {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 60%;
  }
  
  .card-column2 {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
  }
  
  .card-img {
    width: 100%;
    border-radius: 10px 0 0 10px;
    height: 100%;
  }
  
  .row1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10px;
    align-items: center;
  }

  
  .row1-remates h4 {
    margin: 0;
  }
  
  .row1-remates .remate-feria {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-family: 'poppins';
  }
  
  .row1-remates .remate-mensual {
    text-align: end;
    font-size: 1.4rem;
    font-family: 'Kaushan Script', cursive;
    color: #233C36;
  }
  
  .row1-fecha {
    display: flex;
    align-items: center;
    margin: 0;
    font-family: 'poppins';
  }

  .row1-fecha .calendario-img{
    height: 25px;
  }
  
  .row1-fecha h4 {
    font-size: 1rem;
    margin-left: 5px;
  }
  
  .row2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 8%;
    margin-left: 10%;
    margin-top: 10px;
    align-items: normal !important;
  }
  
  .row2 .destacamos {
    font-size: 17px;
    color: #233C36;
    text-transform: uppercase;
    margin-top: 5px;
    font-style: italic;
    font-family:'poppins';
    font-weight: bold;
  }

  .card .hacienda{
    font-size: 17px;
  }

  .importante-flex{
    display: flex;
    align-items: center;
    background-color: #ccc;
    border-radius: 4px;
    height: 60px;
  }
  .importante-flex img{
    width: 25px;
    margin-left: 5px;
  }
  
  .row2 .importante {
    color:rgba(0,0,0,0.9);
    font-size: 0.8rem;
    padding: 10px 4px;
    margin: 0;
    opacity: 0.8;
  }
  
  .row3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 5%;
    margin-top: 10px;
  }
  
  .column1,
  .column2,
  .column3 {
    margin: 0 2%;
    display: flex;
    align-items: center;
  }
  
  .column1 img,
  .column2 img,
  .column3 img {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .sociedadrural, .horario, .ventacontado {
    font-family:'poppins';
  }
  
  
  .row4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
  }
  
  .boton-remate {
    border: 1px solid black;
    border-radius: 40px;
    padding: 20px 10px;
    align-items: center;
    display: flex;
    color: black;
    font-size: 1rem;
    text-decoration: none;
    font-family: 'poppins';
    font-weight: 600;
    margin-bottom: 10px;
    background: #ffff;
  }
  
  .boton-remate:hover {
    cursor: pointer;
    color: #ffff;
    background-color:#233C36;
    opacity: 0.9;
  }

  
  
  .img-footer-remates{
    width: 100%;
  }




@media (max-width: 1520px) {
  .card{
    width: 80%;
  }
  .card-img-biscardi-desktop{
    width: 80%;
  }
}



@media (max-width: 859px) {
  .card{
    width: 98%;
    display: flex;
    flex-direction: row;
    height: 320px;
  }
  .card-img-biscardi-desktop{
    width: 98%;
  }
  .card-column1 {
    width: 100%;
  }
  
  .row1-remates .remate-feria, .row1-fecha h4, .row1-remates .remate-mensual, .row2 .destacamos, .card .hacienda, .row3 {
    font-size: 12px;
    margin: 0;
  }

  .row1-fecha{
    margin-bottom: 18px;
  }

  .card-column2 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .content-img .cantidad {
    font-size: 4.4rem;
    margin: 0;
    position: relative;
    top: 15px;
  }

  .content-img .vacunos {
    font-size: 2.2rem;
    margin: 0;
  }
  .content-img .tipo {
    font-size: 0.6rem; 
    margin: 0;
    position: relative;
    bottom:15px;
  }     

}

@media (width > 600px) {
  .content-biscardi-mobile{
    display: none;
  }

  .card-img-biscardi-mobile{
    display: none;
  }
}

@media (max-width: 600px) {
  .card-img-biscardi-desktop{
    display: none;
  }
  
  .card{
    height: auto;
  }
  .title-remates {
    font-size: 22px;
  }
  .vaca-title {
    width: 30px;
    height: 30px;
}
  .card{
    width: 84%;
    display: flex;
    flex-direction: column;
  }

  .card-img{
    height: 170px;
    border-radius: 10px 10px 0 0;
  }
  

  .card-column1 {
    width: 100%;
  }
  
  .card-column2 {
    width: 100%;
    height: 258px;
  }

  .content-img{
    top: 72%;
  }
  .content-img .cantidad {
    font-size: 3rem;
    margin: 0;
    height: 60px;
  }

  .content-img .vacunos {
    font-size: 1.5rem;
    margin: 0;
    height: 45px;
  }
  .content-img .tipo {
    font-size: 0.4rem;
  } 
  .row1 {
    margin-left: 4%;
    margin-right: 6%;
    margin-top: 0;
  }
  .row1-fecha{
    margin: 0;
  }
  .row1-fecha h4 {
    font-size: 0.7rem;
    margin-left: 5px;
  }
  .row1-fecha .calendario-img{
    height: 20px;
  }
  .row1-remates .remate-feria,
  .row1-remates .remate-mensual {
    font-size: 1rem;
    text-transform:none;
    margin: 0;
    text-align: center;
  }

  .card .hacienda {
    margin: 0;
    font-size: 0.7rem;
    font-weight: 400;
  }

  .row2{
    margin-top: 4px;
    margin: 10px;
  }

  .row2 .destacamos{
    margin: 0;
  }

  .row2 .importante {
    font-size: 0.5rem;
  }

  .importante-flex{
    height: 40px;
  }

  .row3{
    margin-top: 0;
  }
 
  .boton-remate {
    padding: 15px;
    font-size: 12px;
    margin-bottom: 10px;
    border: none;
    color: #ffff;
    background-color: #233C36;
  }

 
  .column1 img, .column2 img, .column3 img {
    height: 20px;
    width: auto;
    margin-top: 5px;
  }
  .column1 h4, .column2 h4, .column3 h4 {
    font-size: 0.5rem;
  }

}
