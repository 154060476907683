
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:wght@100&family=Secular+One&display=swap');


.imagen-nav {
  position: relative;
  height: 50rem;
}

.imagen-nav img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navbar-container {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 100;
}

.imagen-nav::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 48%);
}

.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color:#233C36;
  opacity: 0.9;
  width: 100%;
  margin: 0 auto;
}

.navbar-icon {
  height: 60px;
  margin-left: 90px;
}

.navbar-section{
  margin-right: 90px;
}


.Navbar a {
  margin: 0 10px;
  padding: 10px 16px;
  text-decoration: none;
  color: #ffff;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  position: relative;
  border-radius: 4px;
}

.Navbar a::after {
  content: '';
  position: absolute;
  left: 0;
  top: 85%;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to right, #efd79b, #efd79b);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 0 2px;
  transition: background-size 0.3s ease;
}

.Navbar a:hover::after {
  background-size: 74% 2px;
}

.Navbar a:hover{
  color: #efd79b;
}

.Navbar.hidden {
  display: none;
}

.MobileNavbar {
  display: none;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #233C36;
  z-index: 2;
  
}

.MobileNavbar a {
  background-color: transparent;
  padding: 10px;
  margin: 10px;
  text-decoration: none;
  color: white;
}

.hamburger-menu {
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 3;
}


.hamburger-menu .line {
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  margin: 6px 0;
  transition: transform 0.3s ease;
}

.hamburger-menu.active .line.line1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-menu.active .line.line2 {
  opacity: 0;
}

.hamburger-menu.active .line.line3 {
  transform: rotate(-45deg) translate(5px, -5px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}


.carousel-container {
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.carousel .slide img{
  width: 550px;
}

.carousel-arrow {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background: none;
  border: none;
  outline: none;
  padding: 4px;
  /* Agrega estilos adicionales según tus necesidades */
}

.carousel-arrow.prev {
  left: 20px;
  border-radius: 25%;
}

.carousel-arrow.next {
  right: 20px;
  border-radius: 25%;

}


.carousel-item {
  color: #ffffffce;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}


.fas-carousel-icon{
  width: 60px !important; 
  height:60px !important;

}

.fa-map-marker-alt{
  margin-left: 25px;
  height:50px !important;
  color: #efd79b !important;
  font-size: 35px !important;
  display: flex;
  align-items: center;
}



.carousel-description{
  margin: 0;
  margin-bottom: 20px;
  position: relative;
  bottom: 60px;
  font-family: 'Poppins', sans-serif;
  
  font-size: 18px;
  color: #ffff;
}

.button-remate {
  background-color: transparent;
  position: absolute;
  bottom: 10px;
  padding: 8px 20px;
  text-decoration: none;
  margin: 20px;
  border-radius: 4px;
  background-color: #efd79b;
  font-weight: 500;
  color: black;
  cursor: pointer;
}
.button-remate:hover {
  background-color: #c3b1839f;

}

.carousel-ubicacion{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  bottom: 45px;
  right: 20px;
  
}

.carousel-ubicacion h4{
  color: #efd79b;
  font-size: 18px;
  font-family: 'poppins';
}


.carousel-arrow:hover{
  
  cursor: pointer;
}

.fas, .fa-solid {
  color: white;
  font-weight: 900;
  font-size: 45px;
}

.navbar-icon-mobile{
  display: none;
}


@media (max-width: 1000px) {
  .navbar-icon {
    height: 40px;
    margin-left: 0;
  }
  .Navbar a{
    padding: 0;
  }
  .Navbar a:hover::after {
    background-size: 0;
  }
  
  .carousel-ubicacion{
    margin-left:20px;
  }
  .Navbar a:hover{
    background-color: transparent;
    
  }

  .navbar-section{
    margin-right: 20px;
  }
}

/* Estilos para dispositivos móviles */
@media (max-width: 550px) {

  .imagen-nav{
    height: 40rem;
  }
  .Navbar {
    display: none;
  }

  .navbar-container{
    background-color: #233C36;
    height: 60px;
  }

  .carousel-item{
    margin-top: 70px;
  }
  

  .mobile-icon{
    position: absolute;
    left: 0;
    width: 10%;
    height: 10%;
  }

  .MobileNavbar {
    display: flex;
  }

  .hamburger-menu {
    display: block;
  }

  .carousel .slide img{
    width: 92%;
    height: auto;
  }

  .carousel-ubicacion{
    margin-top: 10px;
    margin-left: 38px;
    bottom: 60px;
    display: flex;
    align-items: center;
  }

  .carousel-ubicacion h4{
    font-size: 16px;
    font-family: none;
  }

  .carousel-bolivar{
    margin-top: 40px;
  }

  .carousel-image{
    margin-top: 26px;
    width: 40px !important; 
    height: 40px !important;
  }
  .fa-map-marker-alt{
    width: 20px;
    font-size: 25px !important;
    margin-top: 10px;
  }

  .carousel-description {
    font-size: 3.5vw;
    bottom: 80px;
    font-family: none;
   
  }

  .button-remate{
    margin-bottom: 45px;
    font-size: 14px;
  }

  .carousel-arrow {
    top: 40%;
    display: none;
  }

  .carousel-arrow.prev{
    left: 0;
  }

  .carousel-arrow.next{
    right: 0;
  }

  .fas, .fa-solid {
    color: white;
    font-weight: 900;
    font-size: 6vw;
  }

  .carousel-arrow:hover{
    font-style: none;
  }

  .navbar-icon-mobile {
    display: block;
    height: 10vw;
    margin-left: 10px;
    margin-top: 10px;
  }
}