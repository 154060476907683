footer{
    background-color: #212827;
    height: 140px;
    width: 100%;
}

.footer-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.footer-column1{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-top: 30px;
}

.footer-column2{
    margin-right: 50px;
    margin-top: 30px;
}

.img-footer{
    width: 250px;
    height: auto;
}

.derechosreservados{
    color: white;
    font-family: 'poppins';
    font-weight: 400;    
}



.nuestrasredes{
    margin: 4px;
    color: white;
    font-family: 'poppins';
    text-align: center;
}

.img-ig, .img-facebook, .img-mail{
    margin-right: 5px;
    margin-left: 5px;
    width: 40px;
    height: auto;
    margin-top: 10px;
    cursor: pointer;
}




.designer-footer, .developer-footer{
    font-size: 12px;
    display: flex;
    align-items: center;
    color: white;
    font-family: 'poppins';
    cursor: pointer;
}

.designer-img, .developer-img{
    margin-right: 5px;
}


.footer2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    background-color: #161616e6;
    height: 100%;
    
}

.footer2-nombres{
    display: flex;
    gap: 5px;
}
.link-wsp {
    background-color: transparent;
    list-style: none;
    display: flex;
    text-decoration: none;
    color: inherit;
    align-items: center;
}

@media (max-width: 600px) {
    footer{
        height: 120px;
    }
    .footer-column1, .footer-column2 {
        margin-left: 20px;
        margin-top: 30px;
        margin-right: 20px;
    }
    .img-footer {
        width: 145px;
        height: auto;
    }
    .nuestrasredes {
        font-size: 0.7rem;
    }
    .img-ig, .img-facebook, .img-mail {
        width: 28px;
        margin-top: 4px;
    }
    .footer2 {
        flex-direction: column;
        gap: 0;
        background-color: #161616e6;
        height: 100%;
    }
    .derechosreservados {
        font-size: 0.7rem;
        margin-top: 10px;
    }
    .footer2-nombres {
        display: flex;
        gap: 10px;
    }
    .designer-footer, .developer-footer {
        font-size: 0.6rem;
        margin: 0;
    }
    

}